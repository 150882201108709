import React, { useState } from 'react'
import { Helmet } from 'react-helmet'
import { Link } from "gatsby";

import logoTop from "../../images/clubdia/logo-top.png"
import fourOne from "../../images/clubdia/four_one.png"
import setaFour from "../../images/clubdia/seta-four.png"
import fourTwo from "../../images/clubdia/four_two.png"
import fourThree from "../../images/clubdia/four_three.png"
import imageFive from "../../images/clubdia/image_five.png"
import fiveOne from "../../images/clubdia/five_one.png"
import fiveTwo from "../../images/clubdia/five_two.png"
import fiveThree from "../../images/clubdia/five_three.png"
import imageSixNovo from "../../images/clubdia/image_six_novo.png"
import logoFooter from "../../images/clubdia/logo_footer.png"
import instagram from "../../images/clubdia/instagram.png"
import linkedin from "../../images/clubdia/linkedin.png"
import you from "../../images/clubdia/you.png"
import tiktok from "../../images/clubdia/tiktok.png"
import twitter from "../../images/clubdia/twitter.png"

import img from "../../images/clubdia/bg_six.png"
import img2 from "../../images/clubdia/bg-footer.png"

import arrow from '../../images/app/arrow.svg'


/* COMPONENTS */
// import Layout from '../../components/Layout/index'
import '../styles/cupons.scss'

const DuvidasFrequentes = props => (
  <div className="tab unselected">
    <input id={props.DuvidaNumber} type="radio" name="duvidas"/>
    <label className="titleCollapse font-size-1" htmlFor={props.for}> {props.duvidaTitle}
      <img className="arrowDuvidas lightMode" src={arrow} alt='icone seta' />
    </label>
    <div className="tab-content">
      <div className="row">
        <div className="col-md-11 mx-auto font-size-1">
          {props.answer}
        </div>
      </div>
    </div>
  </div>
)

const Cupons = () => {
    const [verMais, setVerMais] = useState(false)

  // const breadC = {
  //   visible: true,
  //   color: 'black',
  //   items: [
  //     { path: '/', name: 'Home' },
  //     { path: '/clubdia/cupons/', name: 'Cupons' }
  //   ]
  // }

  return (
    // <Layout breadCrumb={breadC}>
    <div>
      <Helmet>
        <html lang="pt-BR" amp />
        <meta charSet="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>ClubDIA Cupons | DIA Supermercados</title>
        <meta name="description" content="Cupons do ClubDIA" />
        <meta name="keywords" content="cupons, clubdia" />
        <meta name="author" content="DIA Supermercados" />
      </Helmet>
      <body>
    <main>
        <section id="hoom__section_logo">
            <img src={logoTop} className="hoom__animated hoom__fadeInDown delay-1" alt=""/>
        </section>

        <section id="hoom__section_one">
            <div className="hoom__informacao">
                <div className="hoom__descricao">
                    <h1 className="hoom__titulo hoom__animated hoom__fadeInRight delay-1">
                        Mais<span className="hoom__subtitulo">Economia?</span>
                        vem para o<span className="hoom__subtitulo">Club Dia!</span>
                    </h1>
                    <p className="hoom__animated hoom__fadeInRight delay-2">Receba toda semana cupons exclusivos para pagar ainda menos no DIA!</p>
                </div>
                <form className="hoom__formulario  hoom__animated hoom__fadeInRight delay-3" action="hoom-interativa_board_8070728854_302d02694001906cef28__21843231@use1.mx.monday.com" method="post">
                    <label for="nome">
                        <input type="text" id="nome" name="nome" placeholder="Nome completo" required/>
                    </label>
                    <label for="cpf">
                        <input type="text" id="cpf" name="cpf" placeholder="CPF" required/>
                    </label>
                    <label for="email">
                        <input type="email" id="email" name="email" placeholder="E-mail" required/>
                    </label>
                    <label for="indicacao">
                        <input type="text" id="cupom" name="cupom" placeholder="Cupom" required/>
                    </label>
                    <button type="submit">Enviar</button>
                </form>
            </div>
        </section>        

        <section id="hoom__section_three">
            <div className="hoom__informacao">
                <h2 className="hoom__titulo">Ainda não é cliente Club Dia?</h2>
                <h3 className="hoom__subtitulo">Veja como é fácil</h3>
            </div>
        </section>

        <section id="hoom__section_four">
            <div className="hoom__engloba_box_four">
                <div className="hoom__box_four">
                    <div className="hoom__imagem">
                        <img src={fourOne} alt="Ícone"/>
                    </div>
                    <div className="hoom__descricao">
                        <p className="hoom__desc">Baixe ou Atualize o Club Dia</p>
                    </div>
                </div>
                <div className="hoom__box_seta">
                    <div className="hoom__imagem">
                        <img src={setaFour} alt="Ícone"/>
                    </div>
                </div>
                <div className="hoom__box_four">
                    <div className="hoom__imagem">
                        <img src={fourTwo} alt="Ícone"/>
                    </div>
                    <div className="hoom__descricao">
                        <p className="hoom__desc">Ative o cupom de desconto em 'Meus Cupons'</p>
                    </div>
                </div>
                <div className="hoom__box_seta">
                    <div className="hoom__imagem">
                        <img src={setaFour} alt="Ícone"/>
                    </div>
                </div>
                <div className="hoom__box_four">
                    <div className="hoom__imagem">
                        <img src={fourThree} alt="Ícone"/>
                    </div>
                    <div className="hoom__descricao">
                        <p className="hoom__desc">Informe o CPF no caixa e Economize</p>
                    </div>
                </div>
            </div>
            <div className="hoom__btn">
                <Link to="https://www.dia.com.br/clubdia/">Faça Parte do Club Dia</Link>
            </div>
        </section>

        <section id="hoom__section_five">
            <div className="hoom__engloba_section">
                <div className="hoom__left">
                    <div className="hoom__imagem">
                        <img src={imageFive} alt="Imagem"/>
                    </div>
                </div>
                <div className="hoom__right">
                    <div className="hoom__top">
                        <h3 className="hoom__titulo">Você merece mais no seu Dia!</h3>
                        <h4 className="hoom__subtitulo">Com o Club Dia você aproveita:</h4>
                    </div>
                    <div className="hoom__engloba_box_right">
                        <div className="hoom__box_right">
                            <div className="hoom__image">
                                <img src={fiveOne} alt="ícone"/>
                            </div>
                            <div className="hoom__descricao">
                                <h3 className="hoom__titulo">D<span className="hoom__subtitulo">escontos Exclusivos</span></h3>
                                <p className="hoom__desc">que só quem faz parte do Club Dia tem!</p>
                            </div>
                        </div>
                        <div className="hoom__box_right">
                            <div className="hoom__image">
                                <img src={fiveTwo} alt="ícone"/>
                            </div>
                            <div className="hoom__descricao">
                                <h3 className="hoom__titulo">I<span className="hoom__subtitulo">ncríveis Ofertas</span></h3>
                                <p className="hoom__desc">nos produtos que você mais gosta</p>
                            </div>
                        </div>
                        <div className="hoom__box_right">
                            <div className="hoom__image">
                                <img src={fiveThree} alt="ícone"/>
                            </div>
                            <div className="hoom__descricao">
                                <h3 className="hoom__titulo">A<span className="hoom__subtitulo">cesso Antecipado </span></h3>
                                <p className="hoom__desc">às campanhas e promoções</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section id="hoom__section_six">
            <div className="hoom__engloba_section">
                <div className="hoom__right" style={{ backgroundImage: `url(${img})` }}>
                    <div className="hoom__informacao">
                        <h3 className="hoom__titulo">Os <span className="hoom__bold">1.000 Primeiros</span> Cadastrados Ganharão</h3>
                        <h4 className="hoom__subtitulo">Cupons Exclusivos </h4>
                        <p className="hoom__desc">Corra e Aproveite!</p>
                        <Link to="https://www.dia.com.br/clubdia/">Faça Parte do Club Dia</Link>
                    </div>
                </div>
                <div className="hoom__left">
                    <img src={imageSixNovo} alt=""/>
                </div>
            </div>
        </section>

        <section id="duvidas-frequentes">
            <div className="container pt-5 pb-5">
                <h2 tabIndex={0} className="text-center text-red mb-5 font-Lato-Bold">Ainda tem Dúvidas?</h2>
                <div className="row d-flex">
                    <div className="col-lg-6">
                        <DuvidasFrequentes
                            DuvidaNumber="one"
                            for="one"
                            duvidaTitle="01. O app está disponível em quais sistemas operacionais?"
                            answer={
                                <>
                                    ClubDIA está disponível na:<br />
                                    - versão 7.0 ou superior de Android<br />
                                    - versão 12.0 ou superior de iOS<br /><br />
                                    Acesse a Play Store ou Apple Store e procure <b>&quot;ClubDIA&quot;</b>. Em seguida, clique em instalar e aceitar as permissões necessárias.<br /><br />
                                    Prontinho. Agora é só aproveitar os descontos do nosso app! 😃
                                </>
                            }
                        />
                        <DuvidasFrequentes
                            DuvidaNumber="two"
                            for="two"
                            duvidaTitle="02. O que preciso para me cadastrar"
                            answer={
                                <>
                                    É supersimples se cadastrar no ClubDIA. 😃<br /><br />
                                    Selecione um e-mail válido e crie uma senha com oito caracteres no próprio app. Após isso, um e-mail será enviado na caixa de entrada ou spam. Clicando no link recebido, você estará confirmando seu cadastro. É só preencher as informações solicitadas e pronto. Você é o mais novo cliente do ClubDIA! 👏👏👏
                                </>
                            }
                        />
                        <DuvidasFrequentes
                            DuvidaNumber="three"
                            for="three"
                            duvidaTitle="03. Esqueci a senha. Como posso recuperá-la?"
                            answer={
                                <>
                                    Fique tranquilo. Na tela de login, clique na opção <b>&quot;Esqueci Minha Senha&quot;</b> e automaticamente um e-mail será enviado para você definir sua nova senha. Aí é só aproveitar os cupons de desconto! 😉
                                </>
                            }
                        />
                        <DuvidasFrequentes
                            DuvidaNumber="four"
                            for="four"
                            duvidaTitle="04. Esqueci meu e-mail. Como posso recuperá-lo?"
                            answer={
                                <>
                                    Na tela de login, clique na opção <b>&quot;Esqueci Meu E-mail&quot;</b> e depois digite seu CPF, em seguida, você irá receber uma mensagem de recuperação. Fácil, né?
                                </>
                            }
                        />
                    </div>
                    <div className="col-lg-6">
                        <DuvidasFrequentes
                            DuvidaNumber="five"
                            for="five"
                            duvidaTitle="05. Não tenho mais acesso ao e-mail cadastrado. Como posso alterá-lo?"
                            answer={
                                <>
                                    Clique na opção <b>&quot;Meu ClubDIA&quot;</b>, em seguida <b>&quot;Alterar e-mail&quot;</b> e pronto! Caso não tenha mais acesso ao app, entre em contato com o SAC pelo 📞 <b>0800 723 1470</b> ou pelo e-mail: <b>sac@dia.com.br</b>.
                                </>
                            }
                        />
                        <div className={`d-lg-block d-${verMais === false ? 'none' : 'block'}`}>
                            <DuvidasFrequentes
                                DuvidaNumber="six"
                                for="six"
                                duvidaTitle="06. Já sou cliente ClubDIA, o que preciso fazer para usar o app?"
                                answer={
                                    <>
                                        Acesse o app e realize seu cadastro. Em seguida, é só aproveitar! 😎
                                    </>
                                }
                            />
                            <DuvidasFrequentes
                                DuvidaNumber="seven"
                                for="seven"
                                duvidaTitle="07. Como faço para usar os cupons?"
                                answer={
                                    <>
                                        Entre no app e clique em <b>&quot;Meus Cupons&quot;</b>, selecione o seu estado (SP ou MG) e escolha os cupons que irá utilizar. Quando ativar o cupom, você precisa apenas se identificar no caixa informando seu CPF. Só cliente ClubDIA tem descontos exclusivos! 🛒🛒🛒
                                    </>
                                }
                            />
                            <DuvidasFrequentes
                                DuvidaNumber="eight"
                                for="eight"
                                duvidaTitle="08. Como faço para ativar meus cupons?"
                                answer={
                                    <>
                                        Faça login, em seguida clique no ícone <b>&quot;Meus Cupons&quot;</b>, escolha o estado em que se encontra (SP ou MG) e selecione os cupons que você quer utilizar. Uma vez ativado o cupom no app, você precisará apenas se identificar no caixa da loja informando o seu CPF. Pronto! Agora é só usar.
                                    </>
                                }
                            />
                        </div>
                        <div className={`d-lg-block d-${verMais === false ? 'none' : 'block'}`}>
                            {/* Additional questions */}
                        </div>
                    </div>
                </div>
                <div className="col-12 mt-2 d-lg-none d-block text-center">
                    <p className="mb-2">Mostrando {verMais === false ? '5' : '18'} de 18</p>
                    <div className="row justify-content-center text-center">
                        {verMais === false ? (
                            <button
                                type="button"
                                onClick={() => setVerMais(true)}
                                className="btn btn-water-green col-8 w-100 text-white"
                            >
                                Ver todas as dúvidas
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={() => setVerMais(false)}
                                className="btn btn-water-green col-8 w-100 text-white"
                            >
                                Esconder dúvidas
                            </button>
                        )}
                    </div>
                </div>
            </div>
        </section>


        <section id="hoom__section_eight">
            <Link to="https://www.dia.com.br/clubdia/">Faça Parte do Club Dia</Link>
        </section>
    </main>
    <footer id="hoom__footer" style={{ backgroundImage: `url(${img2})` }}>
        <div className="hoom__engloba_section">
            <div className="hoom__logo">
                <img src={logoFooter} alt="Logo"/>
            </div>
            <div className="hoom__informacao">
                <div className="hoom__top">
                    <h3 className="hoom__titulo">Segue a gente:</h3>
                    <div className="hoom__box_social">
                        <a href="#">
                            <img src={instagram} alt=""/>
                        </a>
                    </div>
                    <div className="hoom__box_social">
                        <a href="#">
                            <img src={you} alt=""/>
                        </a>
                    </div>
                    <div className="hoom__box_social">
                        <a href="#">
                            <img src={linkedin} alt=""/>
                        </a>
                    </div>
                    <div className="hoom__box_social">
                        <a href="#">
                            <img src={tiktok} alt=""/>
                        </a>
                    </div>
                    <div className="hoom__box_social">
                        <a href="#">
                            <img src={twitter} alt=""/>
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</body>
    </div>
    // </Layout>
  )
}

export default Cupons
